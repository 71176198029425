import React from "react";
import styled from "styled-components";
import { notificationAlert } from "../../../../utils/notificationAlert";
import { QuestionButton } from "../atom/QuestionButton";
import { useQuestionContext } from "../questionContext";
import { questionModeEnum } from "../questionMode";
import { questionTypes } from "../questionTypes";
import { StyledSelectQuestionSection } from "./EditQuestionBoard";
import { notification, Dropdown, Space, Menu, Button } from "antd";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import { theme } from "../../../../globalAsset/theme";
import useScreenSize from "../../../../utils/useSmallScreen";
import { screenSizes } from "../../../../globalAsset/mediaQueries";
const questionTypeNames = [
  { name: "Single Line Text" },
  /*   {name: 'Multiple Line Text'}, */
  { name: "Radio Group" },
  { name: "Checkbox" },
  { name: "Dropdown" },
  { name: "Matrix" },
];

const screenQuestionType = [{ name: "Radio Group" }, { name: "Checkbox" }];

export const AddQuestionComponent = (props) => {
  const { isWithinScreenSize } = useScreenSize(screenSizes.tablet);
  const { questionList, setQuestion, questionMode, questionAllowance } =
    useQuestionContext();

  const getQuestionProps = (name) => {
    switch (name) {
      case "Single Line Text":
        return {
          question: "",
          type: questionTypes.singleLine,
          isSaved: false,
        };
      case "Multiple Line Text":
        return {
          question: "",
          type: questionTypes.multipleLine,
          isSaved: false,
        };
      case "Radio Group":
        return {
          question: "",
          options: ["", "", ""],
          type: questionTypes.radioGroup,
          isSaved: false,
        };
      case "Checkbox":
        return {
          question: "",
          options: ["", "", ""],
          type: questionTypes.checkBox,
          isSaved: false,
        };
      case "Dropdown":
        return {
          question: "",
          options: ["", "", ""],
          type: questionTypes.dropDown,
          isSaved: false,
        };
      case "Matrix":
        return {
          question: "",
          options: ["", "", ""],
          categories: ["", "", ""],
          type: questionTypes.matrix,
          isSaved: false,
        };
      default:
        return;
    }
  };

  const openNotification = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() => notification.close(key)}
      >
        Adjust sample needed
      </Button>
    );
    notification["warning"]({
      message: "Question Allowance Exhausted",
      description: "You have exhauted your question allowance",
      btn,
      key,
    });
  };

  const createQuestion = (name) => {
    if (questionAllowance <= 0 && !props.isUnassigned) {
      openNotification();
      return;
    }

    let newQuestionList;

    if (
      questionMode === questionModeEnum.screeningQuestion &&
      questionList.length === 1
    ) {
      const newQuestion = getQuestionProps(name);
      newQuestionList = [newQuestion].map((item, index) => {
        if (!item.isSaved) {
          item.questionId = `${item.type}-${index + 1}`;

          return item;
        }
        return item;
      });
    } else {
      const newQuestion = getQuestionProps(name);

      if (
        newQuestion.type === "matrix" &&
        newQuestion.categories.length > questionAllowance &&
        !props.isUnassigned
      ) {
        notificationAlert(
          "error",
          "No enough question allowance",
          `${questionAllowance} question allowance is less than the requested question`
        );
        return;
      }

      newQuestionList = [...questionList, newQuestion].map((item, index) => {
        if (!item.isSaved) {
          item.questionId = `${item.type}-${index + 1}`;

          return item;
        }
        return item;
      });
    }

    if (!newQuestionList.some((question) => question.isSelect)) {
      newQuestionList[0].isSelect = true;
    }
    setQuestion(newQuestionList);
  };

  const questionNameList = (
    questionMode === questionModeEnum.screeningQuestion
      ? screenQuestionType
      : questionTypeNames
  ).map((item, index) => (
    <QuestionButton
      key={index}
      buttonText={item.name}
      onClick={() => createQuestion(item.name)}
    />
  ));

  const menu = (
    <Menu>
      {(questionMode === questionModeEnum.screeningQuestion
        ? screenQuestionType
        : questionTypeNames
      ).map((item, index) => {
        return (
          <Menu.Item key={index} onClick={() => createQuestion(item.name)}>
            {item.name}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Button
        style={{ backgroundColor: theme.secondaryDark, border: "none" }}
        type="primary"
        onClick={(e) => e.preventDefault()}
        size={isWithinScreenSize ? "mediun" : "large"}
      >
        <Space>
          Add Question
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

const StyledAddQuestion = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  //margin-top: 20px;
`;
