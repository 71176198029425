import React, { useState } from "react";
import styled from "styled-components";
import { Button, Form } from "antd";
import SideMenu from "./SideMenu";
import { AppContent } from "../App";
import { theme } from "../globalAsset/theme";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { closeDrawer } from "./slices/surveyModalSlice";
import withAuthentication from "../HOC/withAuth";
import { SideEffect } from "../Services";
import { notificationAlert } from "../utils/notificationAlert";
import { addActiveSurveyId } from "../Components/Dashboard/slice";
import { device } from "../globalAsset/mediaQueries";
import { SideBarProvider } from "./SideBarProvider";
import CreateSurveyModal from "./CreateSurveyForm";

const AppLayout = ({ children }) => {
  const [form] = Form.useForm();
  const dispatcher = useDispatch();
  const history = useHistory();
  const { visible } = useSelector((state) => state.surveyModalReducer);
  const [loading, setLoading] = useState(false);

  const onFinish = async (data) => {
    try {
      setLoading(true);
      const response = await SideEffect.createSurvey({ data });
      dispatcher(addActiveSurveyId(response.data.data.id));
      localStorage.surveyId = response.data.data.id;
      form.resetFields();
      notificationAlert(
        "success",
        "Survey Created",
        response.data.data.message
      );
      setLoading(false);
      dispatcher(closeDrawer());

      if (data.respondent_type === "unassigned") {
        history.push("/dashboard/create-questionnaire");
      } else {
        history.push("/dashboard/survey-properties");
      }
    } catch (error) {
      setLoading(false);
      console.log({ error3: error });
      if (error.response) {
        notificationAlert(
          "error",
          "Error Occurred",
          error.response?.data?.error
        );
      }
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };
  return (
    <SideBarProvider>
      <Container>
        <SideMenu />

        <div style={{ width: "100%", zIndex: "10" }}>
          <CreateSurveyModal
            onCancel={() => {
              form.resetFields();
              dispatcher(closeDrawer());
            }}
            visible={visible}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            loading={loading}
            form={form}
          />
          <AppContent />
        </div>
      </Container>
    </SideBarProvider>
  );
};

export default withAuthentication(AppLayout);

const Container = styled.div`
  display: flex;
  background: ${theme.background};
  width: 100%;

  @media ${device.desktopL} {
    max-width: 1536px;
    margin: 0 auto;
  }
`;

export const StyledButton = styled(Button)`
  background: linear-gradient(
    to right,
    ${theme.lightOrange},
    ${theme.darkorange}
  );

  color: white;

  &:hover,
  &:focus,
  &:active {
    color: white;
    background: linear-gradient(
      to right,
      ${theme.lightOrange},
      ${theme.darkorange}
    );
  }
`;
