import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { device } from "../../../../globalAsset/mediaQueries";
import { Select, Input, Empty, Col, Row, Button } from "antd";
import {
  FiTrash2,
  FiPlus,
  FiCircle,
  FiChevronDown,
  FiSquare,
  FiPlusSquare,
} from "react-icons/fi";
import { DynamicOption } from "./../elements/DynamicOption";
import { useQuestionContext } from "../questionContext";
import { questionTypes } from "../questionTypes";
import { notificationAlert } from "../../../../utils/notificationAlert";
import { questionModeEnum } from "../questionMode";

const { Option } = Select;

export const EditQuestionBoard = (props) => {
  const {
    questionList,
    setQuestion,
    questionAllowance,
    questionMode,
    screeningAnswerProps,
    setScreeningAnswerProps,
  } = useQuestionContext();
  const [selectedquestionIndex, setSelectedQuestionIndex] = useState(0);
  const [selectedQuestionType, setSelectedQuestionType] = useState();

  useEffect(() => {
    setSelectedQuestionIndex(getSelectedQuestionIndex() + 1);
    setSelectedQuestionType(getQuestionType());
  }, [questionList]);

  const replaceQuestion = (questionList, selectedQuestion) => {
    const newQuestionList = questionList.slice().map((item) => {
      if (item.questionId === selectedQuestion.questionId) {
        return selectedQuestion;
      }

      return item;
    });

    setQuestion(newQuestionList);
  };

  const getSelectedQuestionIndex = () => {
    return questionList.slice().findIndex((item) => item?.isSelect);
  };

  const getSelectedQuestion = () => {
    return questionList.slice().find((item) => item?.isSelect);
  };

  const handleOptionChange = (e, index) => {
    const selectedQuestion = getSelectedQuestion();

    const optionList = selectedQuestion.options.map((option, idx) => {
      if (index === idx) {
        return e.target.value;
      }

      return option;
    });

    selectedQuestion.options = optionList;

    replaceQuestion(questionList, selectedQuestion);
  };

  const handleCategoryChange = (e, index) => {
    const selectedQuestion = getSelectedQuestion();

    const categoryList = selectedQuestion.categories.map((cat, idx) => {
      if (index === idx) {
        return e.target.value;
      }

      return cat;
    });

    selectedQuestion.categories = categoryList;

    replaceQuestion(questionList, selectedQuestion);
  };

  const handleOptionDelete = (index) => {
    const selectedQuestion = getSelectedQuestion();

    if (selectedQuestion.options.length > 1) {
      const newOptions = selectedQuestion.options.filter(
        (item, idx) => index !== idx
      );

      selectedQuestion.options = newOptions;

      replaceQuestion(questionList, selectedQuestion);
    }
  };

  const handleCategoryDelete = (index) => {
    const selectedQuestion = getSelectedQuestion();

    if (selectedQuestion.categories.length > 1) {
      const newCategories = selectedQuestion.categories.filter(
        (item, idx) => index !== idx
      );

      selectedQuestion.categories = newCategories;

      replaceQuestion(questionList, selectedQuestion);
    }
  };

  const handleAddOption = () => {
    const selectedQuestion = getSelectedQuestion();

    if (
      selectedQuestion.type === "matrix" &&
      selectedQuestion.options.length >= 4
    ) {
      notificationAlert(
        "error",
        "Maximum options reached",
        "You can only add a maximum number of 4 options"
      );
      return;
    }

    selectedQuestion.options.push(
      `option${selectedQuestion.options.length + 1}`
    );

    replaceQuestion(questionList, selectedQuestion);
  };

  const handleAddCategory = () => {
    const selectedQuestion = getSelectedQuestion();

    if (
      questionMode === questionModeEnum.questionnaire &&
      selectedQuestion.categories.length + 1 > questionAllowance &&
      !props.isUnassigned
    ) {
      notificationAlert(
        "error",
        "Question allowance exceeded",
        "You do not have enough question allowance for this question"
      );

      return;
    }

    selectedQuestion.categories.push(
      `cat${selectedQuestion.categories.length + 1}`
    );

    replaceQuestion(questionList, selectedQuestion);
  };

  const handleQuestionChange = (e) => {
    const selectedQuestion = getSelectedQuestion();
    selectedQuestion.question = e.target.value;

    replaceQuestion(questionList, selectedQuestion);
  };

  const isQuestionSelected = () => {
    return questionList.slice().some((item) => item.isSelect);
  };

  const questionNumberList = questionList.slice().map((item, index) => {
    return (
      <Option key={index} value={`${index + 1}`}>
        {index + 1}
      </Option>
    );
  });

  const questionTypeList = (
    questionMode === questionModeEnum.screeningQuestion
      ? ["Radio Group", "Checkbox"]
      : ["Radio Group", "Checkbox", "Dropdown"]
  ).map((item, index) => {
    return (
      <Option key={index} value={item}>
        {item}
      </Option>
    );
  });

  const handleChangeQuestionType = (value) => {
    const selectedQuestion = getSelectedQuestion();

    let newQuestionType = "";

    switch (value) {
      case "Radio Group":
        newQuestionType = questionTypes.radioGroup;
        break;
      case "Checkbox":
        newQuestionType = questionTypes.checkBox;
        break;
      case "Dropdown":
        newQuestionType = questionTypes.dropDown;
        break;
      default:
        return;
    }

    selectedQuestion.type = newQuestionType;

    replaceQuestion(questionList, selectedQuestion);

    setSelectedQuestionType(value);
  };

  const handleChangeSelectedQuestion = (value) => {
    const newQuestionList = questionList.slice().map((item, index) => {
      if (`${index + 1}` === value) {
        item.isSelect = true;

        return item;
      }

      item.isSelect = false;

      return item;
    });

    setQuestion(newQuestionList);
  };

  const getQuestionType = () => {
    switch (getSelectedQuestion()?.type) {
      case questionTypes.radioGroup:
        return "Radio Group";
      case questionTypes.checkBox:
        return "Checkbox";
      case questionTypes.dropDown:
        return "Dropdown";
      default:
        return;
    }
  };

  const getOptionIcon = () => {
    switch (getSelectedQuestion()?.type) {
      case questionTypes.radioGroup:
        return <StyledFiCircle />;
      case questionTypes.checkBox:
        return <StyledFiSquare />;
      case questionTypes.dropDown:
        return <StyledFiChevronDown />;
      default:
        return;
    }
  };

  const optionList = getSelectedQuestion()?.options?.map((item, index) => {
    return (
      <DynamicOption
        key={index}
        optionValue={item}
        onOptionChange={(e) => handleOptionChange(e, index)}
        onOptionDelete={() => handleOptionDelete(index)}
        optionIcon={getOptionIcon()}
        hideDelete={getSelectedQuestion().isSaved}
      />
    );
  });

  const categoryList = getSelectedQuestion()?.categories?.map((item, index) => {
    return (
      <DynamicOption
        key={index}
        optionValue={item}
        onOptionChange={(e) => handleCategoryChange(e, index)}
        onOptionDelete={() => handleCategoryDelete(index)}
        optionIcon={<StyledFiCircle />}
        hideDelete={getSelectedQuestion().isSaved}
      />
    );
  });

  return !isQuestionSelected() ? (
    <StyledEmpty />
  ) : (
    <>
      {questionMode === questionModeEnum.screeningQuestion && (
        <div style={{ padding: 20, textAlign: "right" }}>
          <Button
            type="primary"
            onClick={() => {
              setScreeningAnswerProps((prev) => ({ ...prev, visible: true }));
            }}
          >
            Add Screening Answer
          </Button>
        </div>
      )}

      <StyledSelectQuestionSection>
        <p>Select Question</p>
        <StyledSelect
          onChange={handleChangeSelectedQuestion}
          value={selectedquestionIndex}
          defaultValue={selectedquestionIndex}
          bordered={false}
        >
          {questionNumberList}
        </StyledSelect>
      </StyledSelectQuestionSection>

      {getSelectedQuestion()?.type !== questionTypes.singleLine &&
        getSelectedQuestion()?.type !== questionTypes.matrix && (
          <StyledSelectQuestionSection>
            <p>Select Question Type</p>
            <StyledSelect
              onChange={handleChangeQuestionType}
              value={selectedQuestionType}
              style={{ width: "50%", color: "#E48900" }}
              defaultValue={getQuestionType()}
              bordered={false}
            >
              {questionTypeList}
            </StyledSelect>
          </StyledSelectQuestionSection>
        )}
      <StyledEditQuestionBoard iscat>
        <StyledQuestionSection>
          <p>{getSelectedQuestionIndex() + 1}.</p>
          <Input.TextArea
            autoSize={true}
            onChange={handleQuestionChange}
            value={getSelectedQuestion()?.question}
            placeholder="Write question here"
            bordered={false}
          />
        </StyledQuestionSection>
        <StyledEditQuestionBoard
          iscat={getSelectedQuestion()?.type === questionTypes.matrix}
          fullwidth
        >
          {categoryList}
          {Array.isArray(categoryList) &&
            Boolean(categoryList.length) &&
            !getSelectedQuestion().isSaved && (
              <StyledAddQuestion onClick={handleAddCategory}>
                <StyledAddIcon>
                  <StyledFiPlus />
                  <p>Add New Category</p>
                </StyledAddIcon>
              </StyledAddQuestion>
            )}
        </StyledEditQuestionBoard>
        <StyledEditQuestionBoard
          iscat={getSelectedQuestion()?.type === questionTypes.matrix}
          fullwidth
        >
          {optionList}
          {optionList && !getSelectedQuestion().isSaved && (
            <StyledAddQuestion onClick={handleAddOption}>
              <StyledAddIcon>
                <StyledFiPlus />
                <p>Add New Option</p>
              </StyledAddIcon>
            </StyledAddQuestion>
          )}
        </StyledEditQuestionBoard>
      </StyledEditQuestionBoard>
    </>
  );
};

const StyledEditQuestionBoard = styled.div`
  border: ${(props) => (props.iscat ? "0.5px solid #00000029" : "none")};
  border-radius: 5px;
  width: ${(props) => (props.fullwidth ? "100%" : "95%")};
  margin: 0 auto;
  margin-top: 10px;
  padding: 10px;

  & p {
    font-size: 16px;
    margin-bottom: 0px;
  }

  @media ${device.laptop} {
    width: ${(props) => (props.fullwidth ? "100%" : "90%")};
    & p {
      font-size: 1vw;
    }
  }
`;

export const StyledSelectQuestionSection = styled.div`
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  & p {
    font-size: 16px;
    margin-bottom: 0px;
  }

  & h4 {
    font-size: 18px;
  }

  @media ${device.laptop} {
    width: 90%;
    & p {
      font-size: 1vw;
    }

    & h4 {
      font-size: 1.4vw;
    }
  }
`;

const StyledSelect = styled(Select)`
  border-bottom: 1px solid #ccc;
  font-size: 14px;

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
  }

  @media ${device.tablet} {
    font-size: 16px;
  }
`;

const StyledQuestionSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;

  & p {
    margin-bottom: 0px;
  }

  & p:first-child {
    margin-right: 10px;
    color: #a8a8a8;
  }
`;

const StyledDynamicOption = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid #a8a8a8;
  margin-bottom: 15px;
`;

const StyledOptionName = styled.div`
  display: flex;
  justify-content: flex-start;

  & div:first-child {
    margin-right: 10px;
    color: #a8a8a8;
  }
`;

const StyledFiTrash2 = styled(FiTrash2)`
  font-size: 16px;
  cursor: pointer;

  @media ${device.laptop} {
    font-size: 1vw;
  }
`;

const StyledFiSquare = styled(FiSquare)`
  font-size: 16px;

  @media ${device.laptop} {
    font-size: 1vw;
  }
`;

const StyledAddQuestion = styled.div``;

const StyledAddIcon = styled.div`
  display: flex;
  place-items: center;
  color: #656565;
  cursor: pointer;
`;

const StyledFiPlus = styled(FiPlus)`
  background-color: #61d926;
  font-size: 20px;
  color: #fff;
  margin-right: 10px;

  @media ${device.laptop} {
    font-size: 1.4vw;
  }
`;

const StyledEmpty = styled(Empty)`
  margin-top: 30px;
`;

const StyledFiCircle = styled(FiCircle)`
  font-size: 16px;

  @media ${device.laptop} {
    font-size: 1vw;
  }
`;

const StyledFiChevronDown = styled(FiChevronDown)`
  font-size: 16px;

  @media ${device.laptop} {
    font-size: 1vw;
  }
`;
