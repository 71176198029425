import React, { useState } from "react";
import { Form as AntForm, Radio, Typography } from "antd";
import { ModalComponent } from "./Modal";
import { TextInput, Label } from "./Index";
import { StyledButton } from "./AppLayout";

export default function CreateSurveyModal({
  onCancel,
  visible,
  onFinish,
  onFinishFailed,
  loading,
  form
}) {
  const [respondentType, setRespondentType] = useState("assigned");

  return (
    <ModalComponent onCancel={() => {
      setRespondentType('assigned')
      onCancel()
    }} visible={visible}>
      <Typography.Title style={{ textAlign: "center" }} level={3}>
        Create Survey
      </Typography.Title>
      <AntForm
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{ padding: "10px 10px" }}
      >
        <AntForm.Item name="name" label={<Label>Survey Name</Label>}>
          <TextInput required placeholder="Enter survey name" />
        </AntForm.Item>
        <AntForm.Item
          name="respondent_type"
          label={<Label>Select Survey Administration Options</Label>}
        >
          <Radio.Group
            value={respondentType}
            onChange={(event) => {
              setRespondentType(event.target.value);
            }}
            name="respondent_type"
            defaultValue="assigned"
          >
            <Radio value="assigned">
              Administer to Surveyplus Registered Respondents
            </Radio>
            <Radio value="unassigned">
              Will Recruit Respondents and Administer Survey to them
            </Radio>
          </Radio.Group>
        </AntForm.Item>
        {respondentType === "unassigned" && (
          <AntForm.Item
            name="respondent_incentive_amount"
            label={<Label>Incentive Amount (In Naira)</Label>}
          >
            <TextInput
              required
              type="number"
              placeholder="Enter incentive amount"
            />
          </AntForm.Item>
        )}
        <AntForm.Item
          name="sample_needed"
          label={<Label>Number of Respondents</Label>}
        >
          <TextInput
            required
            type="number"
            placeholder="Enter target number of respondents"
          />
        </AntForm.Item>
        <div style={{ width: "100%", textAlign: "center" }}>
          <StyledButton size="large" loading={loading} htmlType="submit">
            Create Survey
          </StyledButton>
        </div>
      </AntForm>
    </ModalComponent>
  );
}
